import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import Footer from "$components/Footer"
import Header from "$components/Header"
import SEO from "$components/SEO"

const H1 = styled.h1`
  color: ${props => props.theme.nero};
  font-size: 2.5rem;
`

const HomeLink = styled(Link)`
  color: inherit;
`

export default () => (
  <>
    <SEO noindex title="Not Found | Lotus Network Solutions" />
    <Header />
    <main>
      <H1>Page Not Found</H1>
      <p>
        The requested page was not found. Try checking that the URL was typed
        correctly, or go to the <HomeLink to="/">homepage</HomeLink>.
      </p>
    </main>
    <Footer />
  </>
)
